import React, { Component } from 'react';
import { post, handleResponse, swal500, DEFAULT_HEADERS } from '../../utils/network';
import { getCookie, setCookie } from '../../utils/utils';
import ClassicLoginComponent from './LoginComponent';
import config from '../../utils/config';
import qs from 'qs';
import { login } from '../../redux/actions';
import { connect } from 'react-redux';

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    const params = qs.parse(props.history.location.search, {
      ignoreQueryPrefix: true,
    });
    this.state = {
      username: '',
      password: '',
      loading: false,
      errorusername: '',
      errorpassword: '',
      successMessage: '',
      token: params.token || null,
      errorLevel: params.level || null,
      errorMessage: params.message || null,
      errorUuid: params.uuid || null,
    };
  }

  errorReset = () => {
    this.setState({
      errorusername: '',
      errorpassword: '',
    });
  };

  emptyInputs = () => {
    const { username, password } = this.state;
    if (username === '') {
      this.setState({ errorusername: 'Enter username' });
    }
    if (password === '') {
      this.setState({ errorpassword: 'Enter password' });
    }
    this.setState({ loading: false });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.errorReset();
    this.setState({
      [name]: value,
    });
  };

  handle401 = err => {
    err.json().then(e => {
      if (e.code === 115) {
        this.setState({
          errorMessage: 'We send an email to verify your account',
          errorLevel: 'success',
        });
      } else {
        this.setState({
          errorpassword: 'Wrong username or password',
          errorusername: 'Wrong username or password',
        });
      }
    });
    return Promise.reject(401);
  };

  handleSubmit = e => {
    e.preventDefault();
    const { username, password } = this.state;
    if (!(username === '' || password === '')) {
      this.setState({ loading: true }, () => {
        const body = {
          username: username,
          password: password,
          locale: 'es',
        };

        post('auth/login', body, false)
          .then(res => handleResponse(res, this.props, [{ status: 401, method: this.handle401 }]))
          .then(data => {
            this.setState({ loading: false }, () => {
              
              if (config.devEnvironment) {
                const name = data.message.firstName ? data.message.firstName.split(' ')[0] : 'User';
                setCookie('userId', data.message.userId);
                setCookie('name', name);
                setCookie('token', data.message.token);
              }
              this.props.login({
                userId: data.message.userId,
                name: data.message.name,
                initials: data.message.initials,
              })
              this.props.history.push('/home');
            });
          })
          .catch(err => {
            this.setState({ loading: false });
            if (err === 404 || err === 400) {
              this.setState({
                errorpassword: 'Wrong username or password',
                errorusername: 'Wrong username or password',
              });
            } else {
              swal500(err);
            }
          });
      });
    } else {
      this.emptyInputs();
    }
  };

  onCloseToast = toastKey => () => this.setState({ [toastKey]: '' });

  componentDidMount() {
    if (getCookie('isLogged')) {
      this.props.history.push('/home');
    }
    if (this.state.token) {
      post('auth/sso', '', false, DEFAULT_HEADERS, false, this.state.token)
        .then(res => handleResponse(res, this.props))
        .then(data => {
          if (config.devEnvironment) {
            const name = data.message.firstName ? data.message.firstName.split(' ')[0] : 'User';
            setCookie('userId', data.message.userId);
            setCookie('name', name);
            setCookie('token', data.message.token);
          }
          this.redirectUser(data.message.permissions);
        });
    }
  }

  render() {
    return (
      <ClassicLoginComponent
        successMessage={this.state.successMessage}
        onCloseToast={this.onCloseToast}
        data={this.state}
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    login: (data) => dispatch(login(data)),
  };
}

export default connect(null, mapDispatchToProps)(LoginContainer);
