import React from 'react';
import { MuiThemeProvider, createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { Provider as ReduxProvider } from 'react-redux';
import Routes from './routes';
import store, { persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
let theme = createTheme({
  palette: {
    primary: {
      main: '#5C9CCC',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#CC8C5C',
    },
    state: {
      success: '#1B9F20',
      failure: '#FF0000',
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['"Roboto"', 'sans-serif'].join(','),
    useNextVariants: true,
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MuiThemeProvider theme={theme}>
          <Routes />
        </MuiThemeProvider>
      </PersistGate>
    </ReduxProvider>
  );
}

export default App;
