import { ListItem, withStyles } from "@material-ui/core";

export const StyledListItem = withStyles(() => ({
  button: {
    '&:hover': {
      backgroundColor: '#d9eefd',
    },
    backgroundColor: '#f1f5f7',
    borderRadius: 5,
    padding: '3px 8px 5px',
    marginTop: 8,
    marginBottom: 8,
  },
}))(ListItem);