import { Typography } from '@material-ui/core';
import { Person as PersonIcon, InfoOutlined } from '@material-ui/icons';
import { LoginInput, PasswordInput, PrimaryButton } from '../../components/common';

const ERROR_LEVEL_COLORS = {
  warning: {
    color: '#856404',
    backgroundColor: '#fff3cd',
    border: '1px solid #ffeeba',
  },
  error: {
    color: '#721c24',
    backgroundColor: '#f8d7da',
    border: '1px solid #f5c6cb',
  },
  success: {
    color: '#155724',
    backgroundColor: '#d4edda',
    border: '1px solid #c3e6cb',
  },
};

export default function ClassicLoginForm({
  classes,
  data,
  handleSubmit,
  handleChange,
  handleClickSwithSignInType,
}) {
  return (
    <>
      {data.errorMessage && (
        <div className={classes.errorContainer} style={ERROR_LEVEL_COLORS[data.errorLevel]}>
          <InfoOutlined className={classes.errorIcon} />
          <div className={classes.errorMessage}>
            {data.errorUuid && <p className={classes.errorUuid}>Código: {data.errorUuid}</p>}
            <p className={classes.errorText}>
              {data.errorUuid && 'Details:'} {data.errorMessage}
            </p>
          </div>
        </div>
      )}
      <div className={classes.inputLogin}>
        <LoginInput
          error={data.errorusername}
          name="username"
          label="Username"
          type="text"
          handleChange={handleChange}
          value={data.username}
          icon={<PersonIcon className={classes.inputIcon} />}
        />
      </div>
      <div className={classes.inputLogin}>
        <PasswordInput
          error={data.errorpassword}
          name="password"
          placeholder="Password"
          handleChange={handleChange}
          value={data.password}
          variant="outlined"
        />
      </div>
      <PrimaryButton type="submit" className={classes.signInButton} onClick={handleSubmit}>
        Sign In
      </PrimaryButton>
      <Typography
        style={{
          cursor: 'pointer',
          fontSize: '14px',
        }}
        onClick={handleClickSwithSignInType}
      >
        Sign in with Google
      </Typography>
    </>
  );
}
