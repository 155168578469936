import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 550,
  },
}));

const PrimaryButton = ({
  children,
  onClick,
  disabled = false,
  startIcon,
  endIcon,
  type,
  style,
  className,
}) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      type={type}
      className={className}
      style={style}
      classes={{
        label: classes.label,
      }}
    >
      {children}
    </Button>
  );
};

const SecondaryButton = ({
  children,
  onClick,
  disabled = false,
  startIcon,
  endIcon,
  type,
  style,
  className,
}) => {
  const classes = useStyles();
  return (
    <Button
      color="primary"
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      type={type}
      className={className}
      style={style}
      classes={{
        label: classes.label,
      }}
    >
      {children}
    </Button>
  );
};

export { PrimaryButton, SecondaryButton };
