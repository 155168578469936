import React, { useEffect, useState } from 'react';
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';

moment.locale('en');
const localizer = momentLocalizer(moment);
const allViews = Object.values(Views).filter(v => v !== 'work_week');
const DragAndDropCalendar = withDragAndDrop(BigCalendar);

const useStyles = makeStyles({
  container: {
    padding: 20,
    height: window.innerHeight - 64 - 40 /*header and padding*/,
    '& .rbc-current-time-indicator': { height: 3, backgroundColor: 'red' },
  },
  loadingContainer: {
    padding: 20,
    height: window.innerHeight - 64 - 40 /*header and padding*/,
    '& .rbc-current-time-indicator': { height: 3, backgroundColor: 'red' },
    opacity: 0.5,
    pointerEvents: 'none',
  }
});

const Calendar = ({ quickLoad, manualLoad, events, editEventTime, fetchEvents, loading }) => {
  const classes = useStyles();
  const [draggedEvent, setDraggedEvent] = useState();
  const [currentView, setCurrentView] = useState(Views.WEEK);
  const [currentDate, setCurrentDate] = useState(moment().subtract(4, 'h').toDate());

  useEffect(() => {
    fetchEvents({
      start: moment(currentDate).startOf(currentView).format(),
      end:   moment(currentDate).endOf(currentView).format()
    });
  }, [currentDate, currentView, fetchEvents]);

  const handleDragStart = e => setDraggedEvent(e);

  const onDropFromOutside = ({ start, end }) => {
    editEventTime({ draggedEvent, start, end });
    setDraggedEvent(null);
  };

  const isOnTheSameDay = event => event.start.getDate() === event.end.getDate()

  return (
    <div className={loading? classes.loadingContainer : classes.container}>
      <DragAndDropCalendar
        onDropFromOutside={onDropFromOutside}
        handleDragStart={handleDragStart}
        onEventDrop={editEventTime}
        onEventResize={editEventTime}
        dragFromOutsideItem={draggedEvent}
        style={{ flex: 1 }}
        localizer={localizer}
        events={events}
        views={allViews}
        startAccessor="start"
        allDayAccessor={() => false}
        endAccessor="end"
        selectable
        resizable
        resizableAccessor={(event) => isOnTheSameDay(event) && currentView !== Views.MONTH}
        draggableAccessor={() => currentView !== Views.MONTH}
        onView={setCurrentView}
        scrollToTime={currentDate}
        onNavigate={setCurrentDate}
        onSelectEvent={e => manualLoad(e, false)}
        onSelectSlot={quickLoad}
        defaultView={currentView}
        timeslots={2}
        step={15}
      />
    </div>
  );
};

export default Calendar;
