import { List, Typography } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { normalizeString } from '../../../utils/utils';
import { criteria } from './criteria';
import { useStyles } from './useStyles';
import { TaskListItem } from './TaskListitem';
import _ from 'lodash';
import { useSelector } from 'react-redux';

export const FilteredTaskList = ({ tasks, ranking, selectedCriteria, taskFilter, handleClickTask }) => {
  const classes = useStyles();
  const pinned = useSelector(state => state.ranking.pinned);
  const alphabetical = criteria[0];

  const filterAndSort = useCallback(
    tasks =>
      tasks
        .filter(t => normalizeString(t.name).includes(normalizeString(taskFilter)))
        .sort(alphabetical.apply(ranking))
        .sort(criteria[selectedCriteria].apply(ranking)),
    [alphabetical, ranking, selectedCriteria, taskFilter]
  );

  const filteredTasks = useMemo(() => {
    const { STARRED, UNSTARRED } = _.groupBy(tasks, t => (pinned.includes(t.id) ? 'STARRED' : 'UNSTARRED'));

    const starredFiltered = filterAndSort(STARRED ?? []).map(t => ({
      ...t,
      pinned: true,
      lastUsed: ranking[t.id]?.lastUsed,
    }));
    const unstarredFiltered = filterAndSort(UNSTARRED ?? []).map(t => ({
      ...t,
      pinned: false,
      lastUsed: ranking[t.id]?.lastUsed,
    }));

    return [...starredFiltered, ...unstarredFiltered];
  }, [tasks, filterAndSort, pinned, ranking]);

  return (
    <List className={classes.list}>
      {filteredTasks.map(t => (
        <TaskListItem task={t} handleClickTask={handleClickTask} />
      ))}
      {filteredTasks.length === 0 && (
        <Typography style={{ fontSize: 15, marginBottom: 10 }}>Couldn't find any tasks</Typography>
      )}
    </List>
  );
};
