import moment from 'moment';
import { createTransform } from 'redux-persist';
import config from '../utils/config';

const SetTransform = createTransform(
  // transform state on its way to being serialized and persisted.

  inboundState => inboundState,

  // transform state being rehydrated
  outboundState => {
    return outboundState.map(timer => {
      let endTimer;

      const timerLastUpdate = timer.lastUpdate && moment(timer.lastUpdate);
      if (
        timerLastUpdate &&
        moment().diff(timerLastUpdate, 'seconds') >= config.maxTimerLiveThresholdSeconds
      ) {
        endTimer = timerLastUpdate;
      } else {
        endTimer = timer.end && moment(timer.end);
      }

      const returnObject = {
        ...timer,
        start: moment(timer.start),
        end: endTimer,
        lastUpdate: timerLastUpdate,
      };

      return returnObject;
    });
  },

  // define which reducers this transform gets called for.
  { whitelist: ['timers'] }
);

export default SetTransform;
