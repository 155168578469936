import {
  Box,
  Button,
  Chip,
  Collapse,
  Container,
  Drawer,
  FormControl,
  InputLabel,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';

import { Star } from '@material-ui/icons';
import { useState } from 'react';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  chip: {
    progress: {
      backgroundColor: '#FFFFFF',
      color: '#F5F5F',
    },
    done: {},
  },
  content: {
    flexGrow: 11,
  },
  drawer: {
    width: 240,
    flexShrink: 0,
    padding: 0,
  },
  drawerPaper: {
    width: 240,
  },
}));

const Mock = () => {
  const classes = useStyles();
  const [selectedProject, SetSelectedProject] = useState(null);
  const [slectedIteration, SetSelectedIteration] = useState('');

  const Tdrawer = (
    <Drawer
      variant="persistent"
      open={true}
      anchor="left"
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
    >
      <Typography>Proyectos</Typography>
    </Drawer>
  );

  return (
    <>
      <div className={classes.root}>
        <div style={{ flexGrow: 1, boxShadow: 'inset -2px 0 2px -2px grey' }}>
          <Typography variant="h4">Projects</Typography>

          <List style={{ marginTop: '25%' }}>
            <ListItem style={{ padding: 0 }}>
              <Button
                style={{
                  width: '100%',
                  borderLeft: '1px solid grey',
                  borderTop: '1px solid grey',
                  borderBottom: '1px solid grey',
                  backgroundColor: 'white',
                  borderRadius: 0,
                }}
                endIcon={<Star style={{ color: '#fac637' }} />}
              >
                Proyecto > Tarea
              </Button>
            </ListItem>
            <ListItem>
              <Button style={{ width: '100%' }} variant="outlined" endIcon={<Star style={{ color: '#fac637' }} />}>
                Proyecto > Tarea
              </Button>
            </ListItem>
          </List>

          <List>
            <Collapse>
              <List>
                <ListItem>
                  <Button style={{ width: '100%' }} endIcon={<Star style={{ color: '#fac637' }} />}>
                    Proyecto > Tarea
                  </Button>
                </ListItem>
              </List>
            </Collapse>
          </List>
        </div>
        <div className={classes.content}>
          <Container>
            <div>
              <Typography style={{ display: 'inline' }} variant="h4">
                Iteration: 
              </Typography>
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={slectedIteration}
                  onChange={evt => {
                    SetSelectedIteration(evt.target.value);
                  }}
                  label="Age"
                >
                  <MenuItem value={10}>Iteracion 1</MenuItem>
                  <MenuItem value={20}>Iteracion 2</MenuItem>
                  <MenuItem value={30}>Iteracion 3</MenuItem>
                </Select>
              </FormControl>
            </div>

            <Typography variant="h6">Stories</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="Left">Labels</TableCell>
                  <TableCell align="Left">ID</TableCell>
                  <TableCell align="Left">Name</TableCell>
                  <TableCell align="Left">State</TableCell>
                  <TableCell align="Left">Responsibles</TableCell>
                  <TableCell align="Left">Effort</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Super Responsible</TableCell>
                  <TableCell>Super State</TableCell>

                  <TableCell>Super tarea</TableCell>
                  <TableCell>
                    <Chip style={{ backgroundColor: '#fc6528' }} label="🚫 Blocked" />
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Chip onDelete={() => {}} color="primary" label="TGL" />
                    <Chip onDelete={() => {}} color="primary" label="MT" />
                    <Chip onDelete={() => {}} color="primary" label="IE" />
                  </TableCell>
                  <TableCell>22.5h</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Super Responsible</TableCell>
                  <TableCell>Super State</TableCell>

                  <TableCell>Super tarea</TableCell>
                  <TableCell>
                    <Chip style={{ backgroundColor: '#86fc28' }} label=" ✔️ Done" />
                  </TableCell>
                  <TableCell>
                    <Chip onDelete={() => {}} color="primary" label="TGL" />
                    <Chip onDelete={() => {}} color="primary" label="MT" />
                    <Chip onDelete={() => {}} color="primary" label="IE" />
                  </TableCell>
                  <TableCell>22.5h</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Super Responsible</TableCell>
                  <TableCell>Super State</TableCell>

                  <TableCell>Super tarea</TableCell>
                  <TableCell>
                    <Chip style={{ backgroundColor: '#fced28' }} label="🕛 In progress" />
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Chip onDelete={() => {}} color="primary" label="TGL" />
                    <Chip onDelete={() => {}} color="primary" label="MT" />
                    <Chip onDelete={() => {}} color="primary" label="IE" />
                  </TableCell>
                  <TableCell>22.5h</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <br></br>
            <Typography variant="h6">Task without Story</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="Left">Labels</TableCell>
                  <TableCell align="Left">ID</TableCell>
                  <TableCell align="Left">Name</TableCell>
                  <TableCell align="Left">State</TableCell>
                  <TableCell align="Left">Responsibles</TableCell>
                  <TableCell align="Left">Effort</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Super Responsible</TableCell>
                  <TableCell>Super State</TableCell>

                  <TableCell>Super tarea</TableCell>
                  <TableCell>
                    <Chip style={{ backgroundColor: '#fc6565' }} label="🚫 Blocked" />
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Chip onDelete={() => {}} color="primary" label="TGL" />
                    <Chip onDelete={() => {}} color="primary" label="MT" />
                    <Chip onDelete={() => {}} color="primary" label="IE" />
                  </TableCell>
                  <TableCell>22.5h</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Super Responsible</TableCell>
                  <TableCell>Super State</TableCell>

                  <TableCell>Super tarea</TableCell>
                  <TableCell>
                    <Chip style={{ backgroundColor: '#86fc65' }} label=" ✔️ Done" />
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Chip onDelete={() => {}} color="primary" label="TGL" />
                    <Chip onDelete={() => {}} color="primary" label="MT" />
                    <Chip onDelete={() => {}} color="primary" label="IE" />
                  </TableCell>
                  <TableCell>22.5h</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Super Responsible</TableCell>
                  <TableCell>Super State</TableCell>

                  <TableCell>Super tarea</TableCell>
                  <TableCell>
                    <Chip style={{ backgroundColor: '#fced65' }} label="🕛 In progress" />
                  </TableCell>
                  <TableCell>
                    {' '}
                    <Chip onDelete={() => {}} color="primary" label="TGL" />
                    <Chip onDelete={() => {}} color="primary" label="MT" />
                    <Chip onDelete={() => {}} color="primary" label="IE" />
                  </TableCell>
                  <TableCell>22.5h</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Mock;
