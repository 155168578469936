import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../redux/actions';
import { getCookie, isLogged } from '../utils/utils';

export default function SSO(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLogged()) {
      localStorage.removeItem('user');
      return props.history.push('/login');
    }
    const userIdFromCookie = getCookie('userId');
    dispatch(
      login({
        userId: userIdFromCookie,
        name: getCookie('name'),
        initials: getCookie('initials'),
      })
    );

    return props.history.push('/home');
  }, [dispatch, props.history]);
  return (
    <></>
  );
}
