import {
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { deleteTimer } from '../../../redux/actions';
import { makeTaskPath } from '../../../utils/utils';
import { PrimaryButton } from '../Button';
import Timer from '../Timer';

const useStyles = makeStyles(() => ({
  dialogPaper: { minHeight: '10vh', maxHeight: '80vh', padding: 4 },
  dialogTitle: { padding: '16px 24px' },
  dialogText: { marginBottom: 0, color: 'black', fontSize: 16 },
  dialogTaskText: { margin: '24px 0px 0px', color: 'black', fontSize: 16 },
}));

const TimerDetails = ({ open, handleClose, selectedTimer, handleClick, reopen, addEvent }) => {
  const classes = useStyles();
  const timers = useSelector(state => state.timers.timers);
  const [timer, setTimer] = useState(null);
  const dispatch = useDispatch();

  const handleDelete = id => {
    handleClose();
    Swal.fire({
      title: 'Are you sure?',
      text: `This will delete timer: ${timer.name}`,
      icon: 'warning',
      showCancelButton: true,
    }).then(result => {
      if (result.value) {
        dispatch(deleteTimer(id));
        return;
      }
      reopen();
    });
  };

  const handleStopIfNotLinked = () => {
    handleClick();
  }

  const [task, setTask] = useState(false);

  useEffect(() => {
    if (!selectedTimer) return;
    setTimer(timers.find(t => t.id === selectedTimer.id));
    if(!timer?.task) {
      setTask(null);
      return;
    }
    const task = timer.task;
    setTask(task ?? null);

  }, [selectedTimer, timers, timer?.task]);


  const TaskDetail = () => {
    if (!task) return null;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 6,
        }}
      >
        <Divider style={{ marginBottom: 6 }}></Divider>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Typography style={{ fontSize: 12 }}>{makeTaskPath(task)}</Typography>
            <Typography style={{ fontSize: 15 }}>{`${task.name}`}</Typography>
          </div>
          <Tooltip title="Edit task">
            <IconButton 
              disabled={timer.end}
              onClick={handleClick}>
              <Edit />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <DialogContentText variant="h5">Timer details</DialogContentText>
        {timer && (
          <div
            style={{
              padding: '6px 6px 0px 6px',
            }}
          >
            <Timer
              addEvent={addEvent}
              info={timer}
              handleAction={timer => {
                handleDelete(timer.id);
              }}
              actionIcon={<Delete />}
              handleStopIfNotLinked={handleStopIfNotLinked}
              actionTooltip={'Delete timer'}
              onStop={(eventAdded) => eventAdded && handleClose()}
            ></Timer>
          </div>
        )}

        {!task ? (
          <PrimaryButton onClick={handleClick}>
            {' '}
            <Typography>Link with Task</Typography>{' '}
          </PrimaryButton>
        ) : (
          <TaskDetail />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TimerDetails;
