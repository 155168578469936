import React from 'react';
import { CircularProgress, withStyles } from '@material-ui/core';

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh',
  },
  text: {
    fontSize: 16,
  },
});

const Loading = ({ classes, style = {} }) => (
  <div className={classes.container} style={style}>
    <CircularProgress color="primary" />
    <br />
    <p className={classes.text}>Obteniendo información...</p>
  </div>
);

export default withStyles(styles)(Loading);
