import React, { useCallback, useEffect, useState, memo } from 'react';
import { isEqual, last } from 'lodash';
import { useHistory } from 'react-router-dom';
import { TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { EMPTY_ARRAY, sleep } from '../../../utils/utils';
import { get, handleResponse, swal500 } from '../../../utils/network';
import useDebounce from '../hooks/useDebounce';
import { textFieldStyle, autocompleteStyle, compactStyle } from './styles';
import _ from 'lodash';

function ApiAutocomplete({
  extraFields, // Allows to to show some fields behind the original field, setted on the 'field' prop
  value,
  label, // Sets the placeholder value. This is not the field values.
  field, // The field that is supposed to be maped on the fields
  error,
  path, // Endpoint path
  handleChange,
  disabled = false,
  showInput = false,
  dependsOn,
  hide,
  freeSolo,
  createText = 'Add',
  filterIds = EMPTY_ARRAY,
  idField = 'id',
  compact,
  helperText,
  mock = false,
  onKeyPress = e => {},
}) {
  const history = useHistory();
  const [optionLabels, setOptionLabels] = useState([]);
  const [options, setOptions] = useState([]);
  const [currentValue, setCurrentValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [previousDependsOnValue, setPreviousDependsOnValue] = useState(dependsOn);
  const basePathWithParams = path && `${path}${path.includes('?') ? '&' : '?'}page=0&size=30`;

  const fillAutocomplete = useCallback(
    newValue => {
      if (!basePathWithParams) return;
      const regex = new RegExp(' ');
      const nameParam = newValue.replace(regex, '%20');
      setLoading(true);
      const like = 'like';
      const endpoint = nameParam ? `${basePathWithParams}&${field}=${like}:${nameParam}` : basePathWithParams;
      if (mock)
        sleep(1000, () => {
          const items = mock
            .filter(i => i[field] && i[field].toLowerCase().includes(newValue.toLowerCase()))
            .filter(i => i[field] && !filterIds.includes(i[idField]));
          setOptions(items);
          setOptionLabels(items.map(option => option[field]));

          if (freeSolo && newValue) {
            setOptions(prevOptions => [...prevOptions, { name: newValue }]);
            setOptionLabels(prevLabels => [...prevLabels, `${createText} "${newValue}"`]);
          }

          setLoading(false);
        });
      else
        !disabled &&
          get(endpoint)
            .then(res => handleResponse(res, { history }, [{ status: 404, method: () => Promise.reject(404) }]))
            .then(parsed => {
              const items = parsed.message.filter(i => i[field] && !filterIds.includes(i[idField]));
              setOptions(items);
              setOptionLabels(items.map(option => option[field]));
              if (freeSolo && newValue) {
                setOptions(prevOptions => [...prevOptions, { name: newValue }]);
                setOptionLabels(prevLabels => [...prevLabels, `${createText} "${newValue}"`]);
              }
            })
            .catch(err => (err === 404 ? setOptions([]) : Promise.reject(err)))
            .catch(swal500)
            .finally(() => setLoading(false));
    },
    [basePathWithParams, field, mock, disabled, freeSolo, filterIds, idField, createText, history]
  );

  useEffect(() => {
    if (value && value[field]) {
      setCurrentValue(value[field]);
      setOptions([value]);
      setOptionLabels([value[field]]);
    } else if (!disabled) {
      fillAutocomplete('');
      setCurrentValue('');
    }
  }, [disabled, field, value, fillAutocomplete]);

  useEffect(() => {
    if (dependsOn !== undefined && dependsOn !== previousDependsOnValue) {
      setPreviousDependsOnValue(dependsOn);
      setCurrentValue('');
      setOptions([]);
      setOptionLabels([]);
      handleChange('');
      fillAutocomplete('');
    }
  }, [dependsOn, disabled, fillAutocomplete, previousDependsOnValue, handleChange]);

  const handleInputChange = (event, newValue) => {
    setCurrentValue(newValue);
    setLoading(true);
    setOptions([]);
    setOptionLabels([]);
    fillAutocomplete(newValue);
  };

  const onInputChange = useDebounce(handleInputChange, 250);

  const handleSelect = (event, newValue) => {
    let selected;
    if (newValue?.startsWith(createText)) selected = last(options);
    else selected = options.filter(o => o[field] === newValue)[0];
    if (selected || !newValue) handleChange(selected || '');
  };

  return hide ? null : showInput ? (
    <TextField
      value={value[field] || value}
      label={label}
      InputProps={{
        readOnly: true,
        disableUnderline: true,
      }}
      style={textFieldStyle}
    />
  ) : (
    <Autocomplete
      options={optionLabels}
      autoHighlight
      disabled={disabled}
      value={currentValue || null}
      onInputChange={onInputChange}
      onChange={(event, newValue) => handleSelect(event, newValue)}
      loading={loading}
      getOptionSelected={(option, value) => isEqual(option, value)}
      getOptionLabel={option => {
        if (freeSolo && option?.startsWith(createText)) return option.split('"')[1];
        else return option;
      }}
      loadingText="Loading..."
      noOptionsText="No options found"
      openText="Open"
      clearText="Delete"
      closeText="Close"
      renderOption={optionLabel => {
        if (!extraFields) return <Typography>{optionLabel}</Typography>;
        const option = options.find(op => op[field] === optionLabel);
        if (!option) return <Typography>{optionLabel}</Typography>;
        const label = extraFields.reduce((acc, f) => acc + ' > ' + _.get(option, f), option[field]);
        return <Typography>{label}</Typography>;
      }} //so it doesn't use getOptionLabel for the options
      freeSolo={freeSolo}
      style={autocompleteStyle}
      renderInput={params => (
        <form>
          <input
            type="password"
            style={{
              width: 0,
              height: 0,
              visibility: 'hidden',
              position: 'absolute',
              left: 0,
              top: 0,
            }}
          />
          <TextField
            {...params}
            style={compact && compactStyle}
            label={label}
            margin="normal"
            error={error !== undefined}
            helperText={helperText || error}
            onKeyPress={onKeyPress}
          />
        </form>
      )}
    />
  );
}

export default memo(ApiAutocomplete);
