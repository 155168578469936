import { Dialog as MUIDialog, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  dialogPaper: { maxHeight: '90vh' },
  dialogTitle: { padding: '16px 24px' },
}));

const Dialog = ({open, handleClose, title, children}) => {
  const classes = useStyles();
  return <MUIDialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
      scroll="paper"
    >
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
    </MUIDialog>
}

export default Dialog;