import { useCallback, useState } from 'react';

function useFormValidation(initialState, validate) {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});

  const resetError = useCallback(name => setErrors(prevState => ({ ...prevState, [name]: undefined })), [setErrors]);

  const handleChange = useCallback(
    event => {
      const { name, value } = event.target;
      resetError(name);
      setValues(prevState => {
        return ({
          ...prevState,
          [name]: value,
        })
      });
    },
    [resetError, setValues]
  );

  const resetValues = useCallback(
    values => {
      setValues(values);
    },
    [setValues]
  );

  const handleBlur = useCallback(
    event => {
      const { name } = event.target;
      const validationErrors = validate(values);
      setErrors(prevState => ({
        ...prevState,
        [name]: validationErrors[name],
      }));
    },
    [values, validate]
  );

  const handleSubmit = event => {
    event?.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    return Object.entries(validationErrors).length === 0;
  };

  return {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    setErrors,
    resetError,
    resetValues,
  };
}

export default useFormValidation;
