import { Chip } from '@material-ui/core';
import React, { memo, useCallback, useMemo } from 'react';
import { TitleNoItems } from '../Title';
import ApiAutocompleteAdd from './ApiAutocompleteAdd';

function AutocompleteChipSection({
  path,
  editMode,
  title,
  field,
  apiField = field,
  idField = 'id',
  history,
  value,
  handleChange,
  likeLocale,
  noItemsText,
  helpText,
  smallScreen,
  mock = false,
  label,
  disabled,
  dependsOn,
  customHandleAdd,
  customHandleDelete,
  error

}) {
  const handleAdd = useCallback(
    newValue =>
      handleChange({
        target: {
          name: field,
          value: [...value, newValue],
        },
      }),
    [handleChange, value, field]
  );

  const handleDelete = (element) => () => {
    if(!editMode) return;
    if(customHandleDelete) return customHandleDelete(element);
    handleChange({
      target: {
        name: field,
        value: value.filter(item => item[idField] !== element[idField]),
      },
    });
  }
  const filterIds = useMemo(() => value.map(t => t[idField]), [value, idField]);
  const noItems = useMemo(() => !editMode && value.length === 0, [value, editMode]);
  return (
    <div>
      <TitleNoItems title={title} text={noItemsText} noItems={noItems} />
      {editMode && (
        <ApiAutocompleteAdd
          path={path}
          field={apiField}
          likeLocale={likeLocale}
          history={history}
          title={label}
          helpText={helpText}
          smallScreen={smallScreen}
          filterIds={filterIds}
          onAdd={customHandleAdd ?? handleAdd}
          mock={mock}
          dependsOn={dependsOn}
          disabled={disabled}
          error={error}
        />
      )}
      {value.length > 0 && (
        <div style={{ marginBottom: 8 }}>
          {value.map((i, index) => (
            <Chip
              key={index}
              style={{ marginTop: 10, marginRight: 10 }}
              label={i[apiField]}
              size="small"
              color="primary"
              variant="outlined"
              onDelete={ editMode ? handleDelete(i) : undefined }
              
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default memo(AutocompleteChipSection);
