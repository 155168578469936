const generateReducer = (reducerHandler, initialState) => {
  const handler = {
    ...reducerHandler, // Expand all the properties we get passed, which is the reducer itself
    execute: (state = initialState, action) =>
      (handler.hasOwnProperty(action.type) && handler[action.type](state, action)) || state,
  };

  return handler.execute; // return the function that will act as the reducer
};

export default generateReducer;
