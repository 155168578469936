import moment from "moment";
import { normalizeString } from "../../../utils/utils";

export const criteria = [
  {
    label: 'Alphabetical',
    apply: () => (t1, t2) => normalizeString(t1.name) > normalizeString(t2.name)? 1 : -1,
  },
  {
    label: 'Most used',
    apply: ranking => (t1, t2) => (ranking[t1.id]?.counter ?? 0) <= (ranking[t2.id]?.counter ?? 0) ? 1 : -1,
  },
  {
    label: 'Recently used',
    apply: ranking => (t1, t2) => moment(ranking[t1.id]?.lastUsed ?? new Date(0)).isSameOrBefore(ranking[t2.id]?.lastUsed ?? new Date(0))? 1 : -1,
  },
];