import * as actions from '../actionTypes';
import generateReducer from './generateReducer';

const initialState = {
  tasks: [],
};

const handler = {
  [actions.SET_TASKS]: (state, action) => ({
    ...state,
    tasks: action.tasks,
  }),
  [actions.ADD_TASK]: (state, action) => ({
    tasks: state.tasks.concat(action.task),
  }),
};

export default generateReducer(handler, initialState);
