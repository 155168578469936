import React, { useEffect, useState } from 'react';
import {
  Divider,
  Drawer as MaterialDrawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight, History } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import AddTimer from './AddTimer';
import Timer from './Timer';
import { DRAWER_WIDTH } from '../../utils/utils';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    zIndex: 1000,
  },
  closeButton: {
    margin: 8,
  },
}));

const renderOptionDefault = option => (
  <ListItem button key={option}>
    <ListItemIcon>
      <History />
    </ListItemIcon>
    <ListItemText primary={option} />
  </ListItem>
);

const Drawer = ({
  handleToggle,
  isOpen,
  options,
  renderOption = renderOptionDefault,
  anchor,
  header,
}) => {
  const classes = useStyles();
  const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 960);
  const rightSide = anchor === 'right';

  useEffect(() => {
    const resize = () => setSmallScreen(window.innerWidth <= 960);

    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  return (
    <MaterialDrawer
      className={classes.drawer}
      variant={smallScreen ? 'temporary' : 'persistent'}
      anchor={anchor}
      open={isOpen}
      onClose={handleToggle}
      classes={{ paper: classes.drawerPaper }}
      ModalProps={{ keepMounted: true }}
      style={{ display: !isOpen && 'none' }}
    >
      <div className={classes.drawerHeader}>
        <IconButton
          className={classes.closeButton}
          style={{ alignSelf: rightSide ? 'flex-start' : 'flex-end' }}
          onClick={handleToggle}
        >
          {rightSide ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
        <Divider />
        {header}
        <div style={{ flex: 1 }}>
          <List>{options.map(option => renderOption(option))}</List>
        </div>
      </div>
    </MaterialDrawer>
  );
};

const IterationsDrawer = ({ handleToggle, isOpen }) => {
  return (
    <Drawer
      anchor="left"
      isOpen={isOpen}
      handleToggle={handleToggle}
      options={['Iteracion 1', 'Iteracion 2', 'Iteracion 3']}
    />
  );
};

const RunningTimer = ({
  timer, handleOpen, addEvent
}) => (
  <div
    style={{
      alignItems: 'center',
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 30,
    }}
  >
    <Timer
      info={timer}
      handleAction={handleOpen}
      addEvent={addEvent}
      actionTooltip={'Link with task'}
      onStop={() => {}}
    />
  </div>
);

const ChronometersDrawer = ({
  handleToggle,
  isOpen,
  handleOpen,
  addEvent,
  handleStopIfNotLinked,
}) => {
  const timers = useSelector(state => state.timers.timers);
  const runningTimer = timers.find(timer => !timer.end);
  const unlinkedTimers = timers
    .filter(timer => !timer.task && timer.id !== runningTimer?.id)
    .reverse();

  const DividerWithText = ({ text }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: 16,
      }}
    >
      <Divider style={{ flex: 'auto' }} />
      <Typography
        style={{
          marginLeft: 10,
          marginRight: 10,
          fontSize: '0.8rem',
        }}
      >
        {text}
      </Typography>
      <Divider style={{ flex: 'auto' }} />
    </div>
  );

  return (
    <Drawer
      anchor="right"
      isOpen={isOpen}
      handleToggle={handleToggle}
      options={unlinkedTimers}
      renderOption={option => (
        <ListItem style={{ display: 'block' }} key={option.id.toString()}>
          <Timer
            info={option}
            handleAction={handleOpen}
            addEvent={addEvent}
            actionTooltip={'Link with task'}
            onStop={() => {}}
          />
        </ListItem>
      )}
      header={
        <div>
          <div
            style={{
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            {runningTimer ? <RunningTimer 
              timer={runningTimer}
              handleOpen={handleOpen}
              addEvent={addEvent}
            /> : <AddTimer />}
          </div>
          <DividerWithText text={'Pending task assignment'} />
        </div>
      }
    />
  );
};

export { ChronometersDrawer, IterationsDrawer };
